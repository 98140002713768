/* index.css */
:root {
  /* Light Mode Variablen */
  --background-color: #ffffff;
  /* Beispiel: weißer Hintergrund im Light Mode */
  --sidebar-color: #f8f8f8;
  --text-color: #000000;
  /* Beispiel: schwarzer Text im Light Mode */
  --primary-color: #cacaca;
  /* Beispiel: Primärfarbe */
  --content-padding: 24px;
  --message-user-background: rgba(255, 255, 255, 0.03);
  --message-robot-background: rgba(255, 255, 255, 0.1);
  --message-text-color: #fff;
  --input-border-color: #b2b2b2;
  --input-border-color-light: rgba(0, 0, 0, 0, 0.3);
  /* Dunkler Rahmen für Light Mode */
  --input-text-color: #000;
  /* Dunkle Schrift für Light Mode */
  --input-background-color: transparent;
  /* Transparenter Hintergrund */
  --input-focus-border-color: #b2b2b2;
  --placeholder-color: rgba(0, 0, 0, 0.5);
  --dark-box: rgba(0,0,0,0.1);
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Dark Mode Variablen */
    --background-color: #212121;
    /* Beispiel: dunkler Hintergrund im Dark Mode */
    --sidebar-color: #171717;
    --text-color: #ffffff;
    /* Beispiel: weißer Text im Dark Mode */
    --primary-color: #ffffff13;
    /* Beispiel: Primärfarbe im Dark Mode */
    --input-border-color: #ffffff77;
    --input-border-color-light: #ffffff30;
    /* Heller Rahmen für Dark Mode */
    --input-text-color: #fff;
    /* Helle Schrift für Dark Mode */
    --input-focus-border-color: #bb86fc;
    --placeholder-color: rgba(255, 255, 255, 0.5);
    --dark-box: rgba(0,0,0,0.3);
  }
}

.ant-spin-dot {
  width: 29px !important;
  height: 22px;
}

.ant-spin-dot-item {
  background-color: var(--primary-color) !important;
}

.chatInput,
.chatInput,
.chatInput:focus {
  background-color: transparent !important;
  /* Stellt den Hintergrund transparent ein */
  border-color: var(--input-border-color) !important;
  /* Verwendet die benutzerdefinierte Rahmenfarbe */
  border-width: 1px;
  padding-right: 70px !important;
  border-radius: 20px !important;
}


.chatInput::placeholder {
  color: var(--placeholder-color) !important;
}

.ant-input-affix-wrapper .ant-input::placeholder,
.ant-input::placeholder {
  color: var(--placeholder-color) !important;
}

.ant-input-affix-wrapper .ant-input:hover::placeholder,
.ant-input:hover::placeholder {
  color: var(--placeholder-color) !important;
}

.ant-input-affix-wrapper .ant-input:focus::placeholder,
.ant-input:focus::placeholder {
  color: var(--placeholder-color) !important;
}

/* Optional: Überschreibe den Rahmenfarbenwechsel beim Hover */
.ant-input:hover {
  border-color: var(--input-focus-border-color) !important;
  /* Ändere die Rahmenfarbe bei Hover */
  background-color: transparent !important;
}

.appLayout,
.innerLayout,
.contentLayout {
  background-color: var(--background-color);
}

.contentLayout {
  height: 100vh;
  margin: auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--content-padding) var(--content-padding) 0 var(--content-padding);
}

.chatInputContainer {
  padding: 0px var(--content-padding) var(--content-padding) var(--content-padding);
}

.chatForm {
  position: relative;
}

/* Stil für ChatInput */
.chatInput {
  flex: 1;
  margin-right: 8px;
  border: 1px solid var(--input-border-color);
  /* Nutze die Variable für die Rahmenfarbe */
  background: var(--input-background-color);
  /* Stelle sicher, dass der Hintergrund transparent ist */
  color: var(--input-text-color);
  /* Nutze die Variable für die Textfarbe */
  padding: 15px;
  border-radius: 20px;
  outline: none;
  /* Entferne den Standard-Fokus-Rahmen */
}

.chatInput:focus {
  border-color: var(--input-focus-border-color);
  /* Ändere die Rahmenfarbe bei Fokus */
}

.chatButton {
  border: none;
  background: var(--button-background-color);
  color: var(--button-text-color);
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.chatIcon {
  color: var(--button-text-color);
}

.addThreadButton,
.addThreadButton:hover {
  border: 1px solid var(--input-border-color) !important;
  /* Nutze die Variable für die Rahmenfarbe */
  background: var(--input-background-color) !important;
  /* Stelle sicher, dass der Hintergrund transparent ist */
  color: var(--input-text-color) !important;
  /* Nutze die Variable für die Textfarbe */
  height: 38px;
  margin-bottom: 10px;
  border-radius: 10px;
}



.messageListItem {
  padding: 0;
  border: none;
  background-color: transparent;
}

.messageContentUser,
.messageContentRobot {
  display: flex;
  align-items: center;
  color: var(--message-text-color);
  padding: 20px;
  border-radius: 20px;
  word-wrap: break-word;
  word-break: break-word;
  margin-bottom: 15px;
  width: 100%;
  align-items: flex-start;
  gap: 14px;
}

.chatIconAssistant,
.chatIconUser {
  width: 30px;
  height: 30px;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.messageContentBody {
  display: flex;
  justify-content: center;
  min-height: 30px;
  flex-direction: column;
}

.messageContentBody ol {
  list-style: none;
  list-style-position: outside;
  counter-reset: item;
  padding-inline-start: 0px;
}

.messageContentBody ul {
  padding-inline-start: 0px;
}

.messageContentBody ol li {
  counter-increment: item;
  margin-bottom: 5px;
  position: relative;
}

.messageContentBody ol ol,
.messageContentBody ol ul,
.messageContentBody ul ol, 
.messageContentBody ul ul { 
  padding-left: 20px; 
}

.messageContentBody ol li:before {
  margin-right: 10px;
  position: absolute;
  content: counter(item);
  opacity: 0.5;
  left: -20px;
}

.messageContentBody code {
  font-size: 12px !important;
  background: black;
  padding: 5px 10px;
  border-radius: 5px;
  color: lime;
  margin: 5px;
}


.messageContentBody>*:not(:last-child) {
  margin-bottom: 20px;
}

.messageContentUser {
  background: var(--message-user-background);
  align-self: flex-end;
  margin-bottom: 0;
}

.messageContentRobot {
  background: var(--message-robot-background);
  align-self: flex-start;
  margin-bottom: 0;
}

.messageContentSystem {
  padding: 0px 34px;
  color: var(--text-color);
}

.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.threadListContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px); /* Ersetzen Sie [HeaderHöhe] durch die tatsächliche Höhe Ihres Headers */
  overflow: hidden;
}

.threadsList {
  flex: 1;
  overflow-y: auto;
}


.threadsList {
  background: transparent !important;
}

.threadsList .ant-menu-item {
  margin: 0;
  width: 100%;
}

.custom_form {
  width: 100%;
}


.sidebar {
  background-color: var(--sidebar-color) !important;
  padding: 5px;
}

.sidebar .ant-menu-item {
  color: var(--text-color) !important;
}

.sidebar .ant-menu-item-selected {
  background-color: var(--primary-color) !important;
}


* {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  /* Halbtransparentes Weiß */
  border-radius: 2px;
}

/* Stil für den Scrollbar-Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  /* Transparenter Hintergrund */
}

/* Stil für den gesamten Scrollbar */
::-webkit-scrollbar {
  width: 4px;
}

/* Versteckt die Scrollbar, ermöglicht aber das Scrollen in WebKit-Browsern */
.ant-input-textarea > textarea::-webkit-scrollbar {
  display: none !important;
}

/* Versteckt die Scrollbar in Firefox */
.ant-input-textarea > textarea {
  scrollbar-width: none !important;
}

/* Verhindert Scrollbar-Blitzen in MS Edge */
.ant-input-textarea > textarea {
  -ms-overflow-style: none !important;
}


/* Form Styling */

/* Anpassungen für Ant Design Formularelemente */

/* Stil für Input und Textarea */
.ant-input,
.ant-input-affix-wrapper,
.ant-input-textarea, .supabase-auth-ui_ui-input {
  background-color: var(--input-background-color) !important;
  border-color: var(--input-border-color) !important;
  color: var(--input-text-color) !important;
}

.ant-input:focus,
.ant-input-affix-wrapper:focus,
.ant-input-textarea:focus,
.ant-input-focused,
.ant-input-affix-wrapper-focused,
.ant-input-textarea-focused, .supabase-auth-ui_ui-input:focus {
  box-shadow: none !important;
  border-color: var(--input-focus-border-color) !important;
}

/* Stil für Select */
.ant-select-selector {
  background-color: var(--input-background-color) !important;
  border-color: var(--input-border-color) !important;
  color: var(--input-text-color) !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-open .ant-select-selector {
  border-color: var(--input-focus-border-color) !important;
  box-shadow: none !important;
  color: var(--input-text-color) !important;
}

.ant-select-focused .ant-select-selection-item {
  color: var(--input-text-color) !important;
}

/* Stil für Select Dropdown */
.ant-select-dropdown {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

/* Stil für Optionen im Select Dropdown */
.ant-select-item {
  color: var(--text-color) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--primary-color) !important;
}

/* Stil für den Hover-Effekt von Optionen */
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--input-focus-border-color) !important;
}

/* Stil für Buttons */
.ant-btn {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}

.ant-btn:hover,
.ant-btn:focus {
  background-color: var(--input-focus-border-color) !important;
  border-color: var(--input-focus-border-color) !important;
}

/* Anpassung für Fehlermeldungen */
.ant-form-item-explain,
.ant-form-item-extra {
  color: var(--text-color) !important;
}

/* Optional: Stil für den Formularcontainer */
.custom_form {
  padding: var(--content-padding);
  background-color: var(--sidebar-color);
  border-radius: 20px;
}

.custom_form label {
  color: var(--text-color) !important;
  margin-top: 10px !important;
}

/* Erhöhtes Padding für Input und Textarea */
.ant-input,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
.ant-input-textarea {
  padding: 12px 15px;
  /* Erhöhen Sie diese Werte entsprechend Ihren Bedürfnissen */
}

/* Spezifische Anpassung für Textarea, falls gewünscht */
.ant-input-textarea textarea {
  padding: 12px 15px;
  /* Konsistentes Padding innerhalb der Textarea */
}

/* Anpassung für Select, um das Padding zu erhöhen */
.ant-select-selector {
  padding: 25px 15px !important;
  /* Erhöht das Padding, um mehr Platz im Inneren zu schaffen */
}

/* Anpassungen für die Höhe der Select-Komponente, um sie an das erhöhte Padding anzupassen */
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: initial;
  /* Anpassung der Zeilenhöhe, um mit dem neuen Padding übereinzustimmen */
}

/* Erhöhte Abstände zwischen Formularfeldern, um eine bessere visuelle Trennung zu gewährleisten */
.ant-form-item {
  margin-bottom: 24px;
  /* Erhöhen Sie den Abstand zwischen den Formularelementen */
}

/* Sicherstellen, dass alle Texte in Ant Design Komponenten die richtige Farbe haben */
.ant-form,
.ant-form-item-label,
.ant-form-item-control-input-content {
  color: var(--text-color) !important;
}

.form-submit-button {
  height: 40px;
  margin-top: 10px;
}

.form-submit-button {
  height: 43px;
}

.form-notification {
  padding: 24px;
  border-radius: 20px;
  background-color: lightgreen;
  width: 100%
}

.chatButton {
  padding: 4px 10px !important;
  height: 32px !important;
}

.auth-wrapper {
  min-height: 100vh;
  background-color: var(--background-color);
  display: flex;
}

.auth-form {
  max-width: 80vw;
  width: 400px;
  margin: auto;
  padding-bottom: 10vh;
}

.auth-form * {
  font-family: 'Source Sans Pro', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
}

.auth-title {
  color: var(--text-color) !important;
  font-size: 2rem !important;
}

.chatButton:disabled {
  opacity: 0.5;
}

.ant-collapse, .ant-collapse-item {
  border: none !important;
  color: var(--text-color) !important;
}

.ant-collapse {
  padding-left: 40px;
}

.ant-collapse-header-text, .messageContentBody  {
  color: var(--text-color) !important;
}

.ant-collapse-content-box .messageContentBody  {
  padding-left: 30px;
}

.ant-collapse-expand-icon svg {
  fill: var(--text-color) !important;
}

.ant-collapse-content {
  background: none !important;
  border: none !important;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Passen Sie die Höhe nach Bedarf an */
  color: white; /* Stellt die Textfarbe für den gesamten Container ein */
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataText {
  color: white; /* Stellt die Textfarbe speziell für den Text ein, falls benötigt */
  text-align: center; /* Zentriert den Text */
  font-size: 1.5rem !important; /* Größere Schriftgröße, passen Sie nach Bedarf an */
}

.supabase-auth-ui_ui-button {
  
}

/* Grundlegende Stilisierung für Markdown-Tabellen */
.messageContentBody table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  box-shadow: 0 0 5px rgba(0,0,0,0.1); /* Leichter Schatten für die Tabelle */
}

.messageContentBody th,
.messageContentBody td {
  border: 1px solid #ddd; /* Rahmenfarbe */
  text-align: left;
  padding: 8px; /* Abstand innerhalb der Zellen */
}

.messageContentBody th {
  white-space: nowrap;
}

.downloadItem {
  padding: 20px !important;
  background: var(--dark-box) !important;
  border-radius: 20px;
}

.downloadItem * {
  color: var(--text-color) !important;
}

.downloadItem a {
  padding: 10px 20px !important;
    height: unset;
}

.messageList {
  padding-bottom: 20px;
}

/* Initialisiert den Zähler für ol und wendet das Zählen nur auf direkte li-Kinder an */
.messageContentBody > ol {
  counter-reset: item; /* Zähler für jede neue ol starten */
}

.messageContentBody > ol > li {
  counter-increment: item; /* Zähler für li-Elemente in der ersten Ebene von ol erhöhen */
  position: relative; /* Ermöglicht die Positionierung des :before Inhalts */
}

.messageContentBody > ol > li:before {
  content: counter(item) ". "; /* Fügt den Zählerstand vor dem li-Element ein */
  position: absolute;
  left: -20px; /* Positioniert den Zähler links vom li-Element */
  opacity: 0.5;
}

/* Verhindert, dass ul in ol das Zählen erbt */
.messageContentBody ol ul {
  list-style-type: disc; /* Behält disc-Stil für ul innerhalb von ol bei */
  margin-left: 20px; /* Fügt ein wenig Einrückung auf der linken Seite hinzu, um Verschachtelung zu zeigen */
}

.messageContentBody ol ul li:before {
  content: none; /* Entfernt den :before Inhalt für li-Elemente in ul innerhalb von ol */
}

.threadSidebar {
  flex: 0 0 260px !important;
    max-width: 260px !important;
    min-width: 200px;
    width: 260px !important;
}




